@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap");

@layer base {
  body {
    @apply font-[Raleway] bg-stone-200 dark:bg-slate-800;
  }
  p {
    @apply text-slate-800 dark:text-stone-200;
  }
  li {
    @apply px-2 cursor-pointer;
  }
  h1 {
    @apply text-rose-800 dark:text-rose-500;
  }
  h2 {
    @apply text-sky-800 dark:text-sky-400 mb-4;
  }
  button {
    @apply border-slate-800 dark:border-slate-200 text-slate-800 dark:text-slate-200 font-medium;
  }
}

.component {
  @apply bg-stone-200 dark:bg-slate-800 text-black dark:text-stone-200;
  /*@apply bg-slate-800 text-neutral-300 font-medium;*/
}

.content-div {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.content-div:hover {
  background-image: linear-gradient(
    to right,
    rgba(21, 82, 99, 1),
    hsla(193, 65%, 24%, 0.8)
  ) !important;
}
